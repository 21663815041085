/*! * https://github.com/YouCanBookMe/react-datetime */
.rdt {
    position: relative;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}
.rdt .rdtPicker {
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0;
}
.rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}
.rdt input.form-control {
    border: 0;
    background-image: linear-gradient(#5433FF, #5433FF), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 30%);
    background-color: rgba(0, 0, 0, 0);
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    width: 100%;
    height: 36px;
    padding: 0px 2px 5px 2px;
    font-size: 14px;
    line-height: 1.428571429;
    display: block;
    color: #555;
}
.rdt input.form-control:focus {
    outline: none;
    background-image: linear-gradient(#5433FF, #5433FF), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 200px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    border-radius: 0.125rem;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    min-width: 160px;
}
.rdtPicker:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #fff;
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
}
.rdtPicker:after {
    border-bottom: 0.4em solid #fff;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px;
}
.rdtPicker {
    display: block;
    top: 40px;
}
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}
.rdtPicker .rdtTimeToggle {
    text-align: center;
    padding: 5px;
    border-radius: 4px;
}
.rdtPicker table {
    width: 100%;
    margin: 0;
    border-color: #fff !important;
    border-collapse: collapse;
}
.rdtPicker td, .rdtPicker th {
    text-align: center;
    padding: 1px;
}
.rdtPicker td {
    cursor: pointer;
}
.rdtDay {
    height: 27px;
    line-height: 22px;
    width: 25px;
    text-align: center;
    padding: 0px;
    border-radius: 50%;
}
.rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
    background-color: #5433ff !important;
    color: #fff;
}
.rdtDays tr .dow {
    border-bottom: 1px solid #e3e3e3;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: inherit;
    padding-bottom: 5px;
    padding-top: 10px;
}
.rdtDays tr .rdtOld, .rdtDays tr .rdtNew {
    color: #eee;
}
.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover {
    background: #eee;
    cursor: pointer;
}
.rdtPicker td.rdtToday {
    position: relative;
}
.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999;
    cursor: not-allowed;
}
.rdtPicker td span.rdtOld {
    color: #999;
}
.rdtPicker td span.rdtDisabled, .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999;
    cursor: not-allowed;
}
.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
}
.rdtPicker th.rdtSwitch {
    width: 50px;
    padding: 5px;
    border-radius: 4px;
}
.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
    border-radius: 50%;
    line-height: 33px;
}
.rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle {
    color: #5433ff;
}
.rdtPrev span, .rdtNext span {
    display: block;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}
.rdtPicker th.rdtDisabled, .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999;
    cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
    cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
    background: #eee;
}
.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}
.rdtPicker button:hover {
    background-color: #eee;
}
.rdtPicker thead button {
    width: 100%;
    height: 100%;
}
td.rdtMonth, td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
td.rdtMonth:hover, td.rdtYear:hover {
    background: #eee;
}
.rdtCounters {
    display: inline-block;
}
.rdtCounters > div {
    float: left;
    width: 40px;
    font-weight: inherit;
    margin: 3px;
    border-radius: 50%;
}
.rdtCounters .rdtCounterSeparator {
    width: 0;
    border: 1px solid transparent;
}
.rdtCounter {
    width: 40px;
}
.rdtCounter .rdtCount {
    padding: 7px;
    height: 24px;
    border: 1px solid transparent;
}
.rdtCounters .rdtCounter:last-child .rdtCount {
    color: #5433ff;
    border-radius: 50%;
    border: 1px solid #5433ff;
}
.rdtCounterSeparator {
    padding: 7px;
    line-height: 100px;
}
.rdtCounter .rdtBtn {
    line-height: 40px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    color: #eee;
    -webkit-transition: all 60ms ease-in;
    -moz-transition: all 60ms ease-in;
    -o-transition: all 60ms ease-in;
    -ms-transition: all 60ms ease-in;
    transition: all 60ms ease-in;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}
.rdtCounter .rdtBtn:hover {
    background: #eee;
    color: #797979;
}
.rdtCounter .rdtCount {
    font-size: inherit;
    line-height: 25px;
}
.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}
.rdtMilli input {
    width: 100%;
    font-size: inherit;
    margin-top: 37px;
}
.rdtMonths, .rdtYears {
    padding-bottom: 10px;
}
.rdtMonths .rdtMonth, .rdtYears .rdtMonth, .rdtMonths .rdtYear, .rdtYears .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
}
.rdtMonths .rdtMonth.rdtActive, .rdtYears .rdtMonth.rdtActive, .rdtMonths .rdtYear.rdtActive, .rdtYears .rdtYear.rdtActive {
    background-color: #5433ff !important;
    color: #fff;
}
