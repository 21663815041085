
.wj-flexgrid {
    height: calc(100vh - 211px);
}


/*.wj-cells .wj-cell.wj-state-selected {*/

/*}*/

.wj-cell {
    border-right: none;
}

.cell-link {
    color: #4C5BFF;
    text-decoration-line: none;
}

.cell-link:hover {
    color: #014486;
    text-decoration-line: underline;
}

.cell-number {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.cell-vertical-middle {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
}

.grid .wj-state-selected {
    background: white !important;
    color: inherit !important;
}

.grid .wj-state-multi-selected {
    background: white !important;
    color: inherit !important;
}

.wj-cells .wj-cell.wj-state-multi-selected {
    background: white !important;
    color: inherit !important;
}
.grid .wj-cells .wj-cell.wj-state-multi-selected {
    background: white !important;
    color: inherit !important;
}

.search-grid-input {
    height: 35px !important;
    min-height: 35px !important;
}

.wj-content.wj-flexgridsearch {
    height: 35px !important;
    min-height: 35px !important;
}

.wj-cell.wj-align-right {
    text-align: left !important;
}

.cell-left {
    text-align: left !important;
}